export enum NavPath {
  HOME = '/',
  ANALYTICS = '/analytics',
  FLEETS = '/fleets',
  VEHICLES = '/vehicles',
  NETWORK = '/network',
  DRIVERS = '/drivers',
  EMPLOYEES = '/employees',
  DRIVER_SHIFTS = '/driver-shifts',
  TRIPS = '/trips',
  TRIP_WITH_ID = '/trips/:tripId',
  CHARTERS = '/charters',
  CHARTER_WITH_ID = '/charters/:charterId',
  ROUTES = '/routes',
  ROUTES_FOR_SHIFT = '/routes-for-shift/:shiftId',
  CALLED_IN_REQUEST = '/called-in-request',
  SETTINGS = '/settings',
  PASSENGERS = '/passengers',
  PASSENGER_WITH_ID = '/passengers/:passengerId',
  FUNDS = '/funds',
  INVOICES = '/invoices',
  BLAISE_ADMIN_DASHBOARD = '/blaise-admin-dashboard',
  CREATE_NEW_AGENCY = '/blaise-admin-dashboard/create-transit-agency',
  TOGGLE_AGENCY_FEATURES = '/blaise-admin-dashboard/transit-agency-features',
  ORGANIZATIONS = '/organizations',
  ORGANIZATION_WITH_ID = '/organizations/:organizationId',
  PUSH_NOTIFICATIONS = '/push-notifications',
  PROFILE = '/profile'
}

export enum PathPermission {
  READ_SUPER_ADMIN = 'read:superadmins',
  READ_AGENTS = 'read:agents',
  FLEETS = 'manage:fleets',
  VEHICLES = 'manage:buses',
  NETWORK = 'manage:busStops',
  DRIVERS = 'manage:drivers',
  EMPLOYEES = 'manage:employees',
  DRIVER_SHIFTS = 'manage:driverShifts',
  TRIPS = 'manage:history',
  ROUTES = 'manage:routes',
  CALLED_IN_REQUEST = 'manage:calledInRequests',
  SETTINGS = 'manage:systemParameters',
  PASSENGERS = 'manage:passengers',
  FUNDS = 'manage:funds',
  INVOICES = 'manage:invoices',
  ORGANIZATIONS = 'manage:organizations',
  PUSH_NOTIFICATIONS = 'manage:pushNotifications',
  PROFILE = 'manage:myProfile',
  FEES = 'manage:fees',
  INVOICE_STRIPE_BUTTON = 'manage:invoicesShowStripeButton'
}
