import { Snackbar as MaterialUISnackbar, SnackbarContent } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import colors from 'src/assets/sass/colors';
import { MessageStatus } from 'src/enums/Snackbar';

export const Snackbar = ({
  isOpen,
  onClose,
  message,
  status
}: {
  isOpen: boolean;
  onClose: () => void;
  message: string;
  status: string | MessageStatus;
}) => {
  let textColor = colors.black;
  let backgroundColor = colors.blaiseGreen;

  if (status === MessageStatus.ERROR) {
    textColor = colors.white;
    backgroundColor = colors.red;
  } else if (status === MessageStatus.OTHER) {
    textColor = colors.black;
    backgroundColor = colors.blaiseLightGray;
  }

  return (
    <>
      {isOpen ? (
        <MaterialUISnackbar
          open={isOpen}
          onClose={onClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
        >
          <SnackbarContent
            style={{
              ...styles.contentContainer,
              backgroundColor,
              color: textColor
            }}
            message={message}
            action={
              <span onClick={onClose} style={styles.iconContainer}>
                <FontAwesomeIcon icon={faTimes} aria-label="close" color={textColor} />
              </span>
            }
          />
        </MaterialUISnackbar>
      ) : null}
    </>
  );
};

const styles = {
  iconContainer: {
    display: 'flex',
    justifyContent: 'center'
  },
  contentContainer: {
    maxWidth: '475px',
    paddingRight: '1.5em'
  }
};
