/* eslint-disable no-undef */

export enum LocalStorageKeys {
  //CalledInRequestTab.js
  CALLED_IN_REQUEST_FILTER_START_DATE = 'blaise-cir-filter-startDate',
  CALLED_IN_REQUEST_FILTER_END_DATE = 'blaise-cir-filter-endDate',
  CALLED_IN_REQUEST_FILTER_STATUS = 'blaise-cir-filter-status',
  CALLED_IN_REQUEST_DATE_SELECTION_FILTER = 'blaise-cir-date-selection',
  CALLED_IN_REQUEST_FILTER_EXPIRATION_DATE = 'blaise-cir-filter-expiration-date',
  CALLED_IN_REQUEST_HIDDEN_COLUMNS = 'blaise-cir-hidden-columns',

  // RideManagement.js
  RIDE_MANAGEMENT_SORTED_COLUMN = 'blaise-rm-sorted-column-ride-management',
  RIDE_MANAGEMENT_HIDDEN_COLUMNS = 'blaise-rm-hidden-columns',
  RIDE_MANAGEMENT_FILTER_START_DATE = 'blaise-rm-filter-startDate',
  RIDE_MANAGEMENT_FILTER_END_DATE = 'blaise-rm-filter-endDate',
  RIDE_MANAGEMENT_FILTER_STATUS = 'blaise-rm-filter-status',
  RIDE_MANAGEMENT_FILTER_EXPIRATION_DATE = 'blaise-rm-filter-expirationDate',

  // RouteShiftManagement.js
  ROUTE_SHIFT_MANAGEMENT_SORTED_COLUMN = 'blaise-rsm-sorted-column-route-shift-management',
  ROUTE_SHIFT_MANAGEMENT_FILTER_END_DATE = 'blaise-rsm-filter-endDate',
  ROUTE_SHIFT_MANAGEMENT_FILTER_START_DATE = 'blaise-rsm-filter-startDate',
  ROUTE_SHIFT_MANAGEMENT_FILTER_EXPIRATION_DATE = 'blaise-rsm-filter-expirationDate',

  // StopManagement.js
  LOCAL_STORAGE_EDITED_STOPS = 'blaise-sm-edited-stops',

  // MyProfile.js
  UNIT_PREFERENCE = 'blaise-unit-preference'
}
