import { lazy, Suspense, useEffect, useState } from 'react';
import * as React from 'react';
import dotenv from 'dotenv';
import { Route, Router } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/styles';
import theme from './assets/sass/theme';
import Navbar from './components/NavBar/NavBar';
import './App.scss';
import { createBrowserHistory } from 'history';
import BlaiseLoader from './components/BlaiseLoader/BlaiseLoader';
import { useAuth0 } from '@auth0/auth0-react';
import Callback from './pages/Callback/Callback';
import config from './config';
import * as Sentry from '@sentry/react';
import { currentUserStore } from './store/user';
import { authStore } from './store/auth';
import { accessTokenContainer, checkPermissions } from './services/authUtils';
import authenticatedAxiosInstance from './axios/axios-authorized';
import { analyticsStore } from './store/analytics';
import { getDriverShifts } from './services/driverShift';
import { checkCalledInRequestNeedsAction } from './services/checkCalledInRequestNeedsAction';
import usePrevious from './hooks/usePrevious';
import { transitAgencyStore } from './store/transitAgency';

import {
  getTransitAgencyFeatures,
  getTransitAgencyGeoJson,
  getTransitAgencyDetails,
  getTransitAgencyAnalyticsURL,
  getAvailablePaymentTypesForTransitAgency
} from './api/transitAgency';
import { getTransitAgencyBusStops } from './api/busStops';
import { getTimezoneOffset } from 'date-fns-tz';
import ErrorBoundary from './components/ErrorBoundary/ErrorBoundary';
import Home from './pages/Home/Home';
import { TransitAgencyFeatures } from './enums/TransitAgency';
import { getTransitAgencyFunds } from './api/funds';
import { IFeature } from './models/IFeature';
import { updateFundsInStore } from './services/funds';
import { getUser } from './api/user';
import { calledInRequestStore } from './store/calledInRequest';
import { checkForUpcomingCharters } from './services/charters';
import { charterRequestStore } from './store/charterRequest';
import { SystemOfMeasurement } from './enums/MeasurementSystem';
import { NavPath, PathPermission } from './enums/MenuNavigation';
import SnackbarProvider from './context/SnackbarContext';
import getDriverShiftsForReboots from './services/DriverShift/getDriverShiftsForReboots';
import { getBuses } from './api/bus';
import { LocalStorageKeys } from './enums/LocalStorageKeys';

const Analytics = lazy(() => import('./pages/Analytics/Analytics'));
const FleetManagement = lazy(() => import('./pages/FleetManagement/FleetManagement'));
const BusManagement = lazy(() => import('./pages/BusManagement/BusManagement'));
const StopManagement = lazy(() => import('./pages/StopManagement/StopManagement'));
const DriverManagement = lazy(() => import('./pages/DriverManagement/DriverManagement'));
const EmployeeManagement = lazy(() => import('./pages/EmployeeManagement/EmployeeManagement'));
const PassengerManagement = lazy(() => import('./pages/PassengerManagement/PassengerManagement'));
const PassengerProfile = lazy(() => import('./pages/PassengerProfile/PassengerProfile'));
const DriverShiftTable = lazy(() => import('./components/DriverShiftTable/DriverShiftTable'));
const RideManagement = lazy(() => import('./pages/RideManagement/RideManagement'));
const CharterManagement = lazy(() => import('./pages/CharterManagement/CharterManagement'));
const Charters = lazy(() => import('./pages/Charters/Charters'));
const Trips = lazy(() => import('./pages/Rides/Rides'));
const RouteShiftManagement = lazy(
  () => import('./pages/RouteShiftManagement/RouteShiftManagement')
);
const RoutesPage = lazy(() => import('./pages/RoutePage/RoutePage'));
const CalledInRequests = lazy(() => import('./pages/CalledInRequests/CalledInRequests'));
const FundsManagement = lazy(() => import('./pages/FundsManagement/FundsManagement'));
const Invoices = lazy(() => import('./pages/Invoices/Invoices'));
const AdminProfile = lazy(() => import('./pages/AdminProfile/AdminProfile'));
const SystemParameters = lazy(() => import('./pages/SystemParameters/SystemParameters'));
const BlaiseAdminDashboard = lazy(
  () => import('./pages/BlaiseAdminDashboard/BlaiseAdminDashboard')
);
const CreateNewTransitAgency = lazy(
  () => import('./pages/CreateNewTransitAgency/CreateNewTransitAgency')
);
const ToggleTransitAgencyFeatures = lazy(
  () => import('./pages/ToggleTransitAgencyFeatures/ToggleTransitAgencyFeatures')
);
const Organizations = lazy(() => import('./pages/Organizations/Organizations'));
const OrganizationDetails = lazy(
  () => import('./features/Organizations/OrganizationDetails/OrganizationDetails')
);
const PushNotifications = lazy(() => import('./pages/PushNotifications/PushNotifications'));

dotenv.config();
const history = createBrowserHistory();

const App = (): JSX.Element => {
  const [hasCalledRequiredEndpointsOnce, setHasCalledRequiredEndpointsOnce] = useState(false);
  const [navBarWidth, setNavBarWidth] = useState('230px');
  const { user, isLoading, isAuthenticated, loginWithRedirect, getAccessTokenSilently } =
    useAuth0();

  const { setCurrentUser, currentUser } = currentUserStore((state) => ({
    setCurrentUser: state.setCurrentUser,
    currentUser: state.currentUser
  }));
  const { setAccessToken, accessToken } = authStore((state) => ({
    setAccessToken: state.setAccessToken,
    accessToken: state.accessToken
  }));
  const { setAnalyticsUrl } = analyticsStore((state) => ({
    setAnalyticsUrl: state.setAnalyticsUrl
  }));

  const {
    getFeature,
    setFeatures,
    setGeoJson,
    setTransitAgencyDetails,
    setTimezoneDetails,
    setEnabledPaymentTypes,
    setBusStops,
    setFunds,
    setBuses
  } = transitAgencyStore((state) => ({
    features: state.features,
    getFeature: state.getFeature,
    setFeatures: state.setFeatures,
    setGeoJson: state.setGeoJson,
    setTransitAgencyDetails: state.setTransitAgencyDetails,
    setTimezoneDetails: state.setTimezoneDetails,
    setEnabledPaymentTypes: state.setEnabledPaymentTypes,
    setBusStops: state.setBusStops,
    setFunds: state.setFunds,
    setBuses: state.setBuses
  }));

  const { setCalledInRequestDetails } = calledInRequestStore((state) => ({
    setCalledInRequestDetails: state.setCalledInRequestDetails
  }));

  const { setUpcomingChartersWithinTwoWeeks } = charterRequestStore((state) => ({
    setUpcomingChartersWithinTwoWeeks: state.setUpcomingChartersWithinTwoWeeks
  }));

  const prevCurrentUser = usePrevious(currentUser);
  const prevIsAuthenticated = usePrevious(isAuthenticated);
  const prevAccessToken = usePrevious(accessToken);
  accessTokenContainer.setAccessTokenSilently(getAccessTokenSilently);

  useEffect(() => {
    if (
      currentUser &&
      prevCurrentUser !== currentUser &&
      !hasCalledRequiredEndpointsOnce &&
      accessToken
    ) {
      setHasCalledRequiredEndpointsOnce(true);
      (async () => {
        const responses = await Promise.all([
          getAnalyticsUrl(),
          getDriverShifts(),
          checkCalledInRequestNeedsAction(),
          setTransitAgencyFeatures(),
          setTransitAgencyGeoJson(),
          setTransitAgencyBuses(),
          setTransitAgencyBusStops(),
          setTransitAgencyEnabledPaymentTypes(),
          setTransitAgencySystemDetails()
        ]);

        const features = responses?.find(
          (response): response is { features: IFeature[] } =>
            typeof response === 'object' && 'features' in response
        );

        const timezone = responses?.find(
          (response): response is { timezone: string } =>
            typeof response === 'object' && 'timezone' in response
        );

        if (features && timezone) {
          const featuresList = features.features;
          const timezoneString = timezone.timezone;
          await checkForCharters(featuresList, timezoneString);
        }

        if (checkPermissions(user, 'execute:lastMinuteShiftChange')) {
          await getDriverShiftsForReboots();
        }
      })();
    }

    return () => {
      localStorage.removeItem(LocalStorageKeys.LOCAL_STORAGE_EDITED_STOPS);
    };
  }, [currentUser, accessToken]);

  useEffect(() => {
    if (isAuthenticated && prevIsAuthenticated !== isAuthenticated) {
      (async () => await getAuth0AccessToken())();
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (accessToken && prevAccessToken !== accessToken) {
      authenticatedAxiosInstance.createAuthenticatedAxiosInstance(accessToken);
      (async () => await getCurrentUser())();
    }
  }, [accessToken]);

  const getCurrentUser = async () => {
    try {
      const currentUserResponse = await getUser();

      setCurrentUser(currentUserResponse);
    } catch (err) {
      console.log('App::getCurrentUser', err);
    }
  };

  const getAuth0AccessToken = async () => {
    try {
      const token = await accessTokenContainer.getAccessTokenSilently()({
        audience: config.apiAudience
      });

      setAccessToken(token);
    } catch (error) {
      console.log('App::getAuth0AccessToken', error);
    }
  };

  const getAnalyticsUrl = async () => {
    try {
      if (currentUser?.transit_agency_id) {
        const analyticsUrls = await getTransitAgencyAnalyticsURL(currentUser.transit_agency_id);

        if (analyticsUrls) {
          setAnalyticsUrl(analyticsUrls);
        }
      }
    } catch (err) {
      console.log('App::getAnalyticsUrl', err);
      setAnalyticsUrl(null);
    }
  };

  const setTransitAgencyFeatures = async () => {
    if (currentUser?.transit_agency_id) {
      try {
        const features = await getTransitAgencyFeatures(currentUser.transit_agency_id);

        if (features?.length > 0) {
          setFeatures(features);
          await checkForFunds(features);
        }

        return { features: features ?? [] };
      } catch (err) {
        console.log('App::setTransitAgencyFeatures', err);
      }
    }
  };

  const setTransitAgencyGeoJson = async () => {
    if (currentUser?.transit_agency_id) {
      try {
        const { zone_geojson } = await getTransitAgencyGeoJson(currentUser.transit_agency_id);

        setGeoJson(zone_geojson);
      } catch (err) {
        console.log('App::setTransitAgencyGeoJson', err);
      }
    }
  };

  const setTransitAgencyBuses = async () => {
    try {
      const response = await getBuses();

      if (response?.length) {
        setBuses(response);
      }
    } catch (error) {
      console.log('App::setTransitAgencyBuses', error);
    }
  };

  const setTransitAgencyBusStops = async () => {
    if (currentUser?.transit_agency_id) {
      try {
        const responseData = await getTransitAgencyBusStops(currentUser.transit_agency_id);
        const stops = responseData?.busStops || responseData?.formattedBusStops;

        if (stops?.length) {
          setBusStops(stops);
        }
      } catch (err) {
        console.log('App::setTransitAgencyBusStops', err);
      }
    }
  };

  const checkForFunds = async (features: IFeature[]) => {
    const hasFundsFeature = features.find(
      (feature) => feature.name === TransitAgencyFeatures.FARE_ASSISTANCE_FUNDS
    );

    if (!hasFundsFeature || !currentUser?.transit_agency_id) {
      return;
    }

    try {
      const includeTrips = false;
      const response = await getTransitAgencyFunds(currentUser.transit_agency_id, includeTrips);

      const formattedFunds = updateFundsInStore(response);

      setFunds(formattedFunds);
    } catch (error) {
      console.log('App::checkForFunds', error);
    }
  };

  const checkForCharters = async (features: IFeature[], transitAgencyTimezone: string) => {
    const hasCharters = features.find((feature) => feature.name === TransitAgencyFeatures.CHARTERS);

    if (!hasCharters) {
      return;
    }

    const upcomingTrips = await checkForUpcomingCharters(transitAgencyTimezone);

    if (upcomingTrips?.length) {
      setUpcomingChartersWithinTwoWeeks(upcomingTrips);
    }
  };

  const setTransitAgencyEnabledPaymentTypes = async () => {
    if (!currentUser?.transit_agency_id) {
      return;
    }

    try {
      const response = await getAvailablePaymentTypesForTransitAgency(
        currentUser.transit_agency_id
      );

      if (response.length) {
        setEnabledPaymentTypes(response);
        setCalledInRequestDetails({ paymentType: response[0].payment_type });
      }
    } catch (error) {
      console.log('App::setTransitAgencyEnabledPaymentTypes', error);
    }
  };

  const setTransitAgencySystemDetails = async () => {
    if (!currentUser?.transit_agency_id) return;

    try {
      const details = await getTransitAgencyDetails(currentUser.transit_agency_id);
      // Check for timezone mismatch
      /* Note that the built in date getTimezoneOffset is positive if local tz is behind UTC
        and the date fns getTimezoneOffset is positive if local tz is ahead of UTC
        thus multiplying one of them by -1 allows us to compare */
      const browserUTCOffset = (new Date().getTimezoneOffset() / 60) * -1;
      const transitAgencyUTCOffset = getTimezoneOffset(details?.timezone) / (60 * 60 * 1000);

      const timezoneDetails = {
        timezoneMismatch: browserUTCOffset !== transitAgencyUTCOffset,
        taTimezone: details?.timezone
      };

      setCurrentUser({
        ...currentUser,
        unit_system:
          localStorage.getItem('blaise-unit-preference') ||
          details.unit_system ||
          SystemOfMeasurement.METRIC
      });
      setTransitAgencyDetails(details);
      setTimezoneDetails(timezoneDetails);

      return { timezone: timezoneDetails?.taTimezone ?? null };
    } catch (error) {
      console.log('App::setTransitAgencyDetails', error);
    }
  };

  const checkPermissionAndRender = (
    permission: string,
    path: string,
    Component: React.FC | React.ComponentClass | React.LazyExoticComponent<React.FC>,
    alternatePath: string | null = null
  ) => {
    return checkPermissions(user, permission) ? (
      <Route path={path} exact component={Component} />
    ) : (
      <Route path={alternatePath ?? path} exact component={AdminProfile} />
    );
  };

  const appIsLoading = isLoading || !currentUser || !accessToken || !hasCalledRequiredEndpointsOnce;

  if (!isLoading && !user) {
    (async () => await loginWithRedirect())();
  }
  // hasCheckedForAnalyticsUrl added here so that the navbar has fully loaded once the app loads.
  if (appIsLoading) return <BlaiseLoader fullScreen={true} />;

  if (!isAuthenticated) {
    return <></>;
  }

  return (
    <Router history={history}>
      <ThemeProvider theme={theme}>
        {' '}
        <SnackbarProvider>
          <Navbar setNavBarWidth={setNavBarWidth} />
          <main style={{ marginLeft: navBarWidth }} id="pageWrap">
            <ErrorBoundary>
              <Suspense fallback={<BlaiseLoader fullScreen={true} />}>
                <Route path={NavPath.ANALYTICS} exact component={Analytics} />
                {/* <Route path="/realtime" exact component={Realtime} /> */}
                {checkPermissionAndRender(PathPermission.FLEETS, NavPath.FLEETS, FleetManagement)}
                {checkPermissionAndRender(PathPermission.VEHICLES, NavPath.VEHICLES, BusManagement)}
                {checkPermissionAndRender(PathPermission.NETWORK, NavPath.NETWORK, StopManagement)}
                {checkPermissionAndRender(
                  PathPermission.DRIVERS,
                  NavPath.DRIVERS,
                  DriverManagement
                )}
                {checkPermissionAndRender(
                  PathPermission.EMPLOYEES,
                  NavPath.EMPLOYEES,
                  EmployeeManagement
                )}
                {checkPermissionAndRender(
                  PathPermission.DRIVER_SHIFTS,
                  NavPath.DRIVER_SHIFTS,
                  DriverShiftTable
                )}
                {checkPermissionAndRender(PathPermission.TRIPS, NavPath.TRIPS, RideManagement)}
                {checkPermissionAndRender(
                  PathPermission.TRIPS,
                  NavPath.CHARTERS,
                  CharterManagement
                )}
                {checkPermissionAndRender(
                  PathPermission.TRIPS,
                  NavPath.TRIP_WITH_ID,
                  Trips,
                  NavPath.TRIPS
                )}
                {checkPermissionAndRender(
                  PathPermission.TRIPS,
                  NavPath.CHARTER_WITH_ID,
                  Charters,
                  NavPath.CHARTERS
                )}
                {checkPermissionAndRender(
                  PathPermission.ROUTES,
                  NavPath.ROUTES,
                  RouteShiftManagement
                )}
                {checkPermissionAndRender(
                  PathPermission.ROUTES,
                  NavPath.ROUTES_FOR_SHIFT,
                  RoutesPage,
                  NavPath.ROUTES
                )}
                {checkPermissionAndRender(
                  PathPermission.CALLED_IN_REQUEST,
                  NavPath.CALLED_IN_REQUEST,
                  CalledInRequests
                )}
                {checkPermissionAndRender(
                  PathPermission.SETTINGS,
                  NavPath.SETTINGS,
                  SystemParameters
                )}
                {checkPermissionAndRender(
                  PathPermission.PASSENGERS,
                  NavPath.PASSENGERS,
                  PassengerManagement
                )}
                {checkPermissionAndRender(
                  PathPermission.PASSENGERS,
                  NavPath.PASSENGER_WITH_ID,
                  PassengerProfile,
                  NavPath.PASSENGERS
                )}
                {checkPermissionAndRender(PathPermission.FUNDS, NavPath.FUNDS, FundsManagement)}
                {checkPermissionAndRender(PathPermission.INVOICES, NavPath.INVOICES, Invoices)}
                {checkPermissionAndRender(
                  PathPermission.READ_SUPER_ADMIN,
                  NavPath.BLAISE_ADMIN_DASHBOARD,
                  BlaiseAdminDashboard
                )}
                {checkPermissionAndRender(
                  PathPermission.READ_SUPER_ADMIN,
                  NavPath.CREATE_NEW_AGENCY,
                  CreateNewTransitAgency
                )}
                {checkPermissionAndRender(
                  PathPermission.READ_SUPER_ADMIN,
                  NavPath.TOGGLE_AGENCY_FEATURES,
                  ToggleTransitAgencyFeatures
                )}
                {getFeature(TransitAgencyFeatures.ORGANIZATIONS) &&
                  checkPermissionAndRender(
                    PathPermission.ORGANIZATIONS,
                    NavPath.ORGANIZATIONS,
                    Organizations
                  )}
                {getFeature(TransitAgencyFeatures.ORGANIZATIONS) &&
                  checkPermissionAndRender(
                    PathPermission.ORGANIZATIONS,
                    NavPath.ORGANIZATION_WITH_ID,
                    OrganizationDetails,
                    NavPath.ORGANIZATIONS
                  )}
                {checkPermissionAndRender(
                  PathPermission.PUSH_NOTIFICATIONS,
                  NavPath.PUSH_NOTIFICATIONS,
                  PushNotifications
                )}
                <Route path={NavPath.HOME} exact component={Home} />
                <Route path={NavPath.PROFILE} exact component={AdminProfile} />
                <Route path="/callback" exact component={Callback} />
              </Suspense>
            </ErrorBoundary>
          </main>
        </SnackbarProvider>
      </ThemeProvider>
    </Router>
  );
};

export default Sentry.withProfiler(App);
// For now, withProfiler HOC is still recommended over using hook.
// https://github.com/getsentry/sentry-javascript/issues/3408#issuecomment-1181794291
