import { zonedTimeToUtc } from 'date-fns-tz';

type DateTime = string | number | Date;

// get unix timestamp in ms
export const getUnixTimeInMS = (dateTime: DateTime) => {
  return new Date(dateTime).getTime();
};

export const isSameDateTime = (dateTimePrimary: DateTime, dateTimeSecondary: DateTime) => {
  return getUnixTimeInMS(dateTimePrimary) === getUnixTimeInMS(dateTimeSecondary);
};

export const getISOStringInUTC = (dateTime: DateTime, transitAgencyTimeZone: string) => {
  return zonedTimeToUtc(dateTime, transitAgencyTimeZone).toISOString();
};

export const isDateTimeValid = (dateTime: Date) => {
  if (!dateTime) return false;

  return !isNaN(dateTime.getTime());
};

export const areDatesTheSame = (firstDate: Date, secondDate: DateTime) => {
  // In case the dates are not of type Date
  const firstDateTime = new Date(firstDate);
  const secondDateTime = new Date(secondDate);
  const isSameDay =
    firstDateTime.getFullYear() === secondDateTime.getFullYear() &&
    firstDateTime.getMonth() === secondDateTime.getMonth() &&
    firstDateTime.getDate() === secondDateTime.getDate();
  return isSameDay;
};
