import { DriverWithAssignedShifts } from 'src/models/Driver';
import authenticatedAxiosInstance from '../axios/axios-authorized';
import { AxiosResponse } from 'axios';
import { DriverShiftForReboot } from 'src/models/DriverShifts';

interface IEditDriverShiftReqBody {
  driverId: number;
  busId: number;
  startTime: string;
  endTime: string;
  startDepot: number;
  endDepot: number;
  requestShiftChanges: boolean;
  deadheading: boolean;
  timeIsLocal: boolean;
}

interface ICreateBulkDriverShiftsReqBody {
  shiftDurationSeconds: number;
  shiftStartTime: string;
  recurrenceStartDate: string;
  recurrenceEndDate: string;
  applicableDayOfWeek: {
    [key: number]: boolean;
  };
  driverId: number;
  busId: number;
  startDepot: number;
  endDepot: number;
  deadheading: boolean;
  dryRun: boolean;
}

interface IGetDriverShiftDetailsResponse {
  bus: {
    bus_id: number;
    bus_name: string;
    bus_number: number;
  };
  driver: {
    driver_name: string;
  };
  end_time: string;
  routes: {
    current_stop_sequence: number | null;
    departure_time: string | null;
    is_active: number;
    is_completed: number;
    route_id: number;
    route_idx: number;
    uuid: string | null;
  }[];
  shift_id: number;
  start_time: string;
}

export interface IAvailableShift {
  shiftId: number;
  startTime: string;
  endTime: string;
  startDepot: number;
  endDepot: number;
  busId: number;
  driverId: number;
  deadheading: boolean;
  bus?: {
    busId: number;
    busName: string;
    isActive: number;
    busFleetId: number;
    busFleet: {
      busFleetId: number;
      busType: string;
      wheelchairCapacity: number;
      bikeCapacity: number;
      nonDisabledCapacity: number;
    };
  };
  driver?: {
    driverId: number;
    driverName: string;
  };
  tripType?: string;
}

export type DemandCapacities = {
  numBikes: number;
  numNonDisabled: number;
  numWheelchairs: number;
};

type DriverShiftsForRebootReqbody = {
  driverShifts: number[];
};

export const getShiftFieldsToUpdate = async (
  shiftId: number
): Promise<{
  result: string;
  message?: string;
  has_permission_last_minute_shift_change?: boolean;
}> => {
  const response = await authenticatedAxiosInstance.axios.get(
    `/drivershift/${shiftId}/canUpdateFields`
  );

  return response.data;
};

export const getDriversAndShifts = async (): Promise<DriverWithAssignedShifts[]> => {
  const response = await authenticatedAxiosInstance.axios.get(`/drivershift/currentAndUpcoming`);

  return response.data;
};

export const getDriverShiftDetails = async (
  shiftId: number
): Promise<IGetDriverShiftDetailsResponse> => {
  const response = await authenticatedAxiosInstance.axios.get(`/drivershift/getDetails/${shiftId}`);

  return response.data;
};

export const editDriverShift = async (
  shiftId: number,
  reqBody: IEditDriverShiftReqBody
): Promise<string | null> => {
  return (await authenticatedAxiosInstance.axios.put(`/drivershift/${shiftId}`, reqBody)).data
    ?.message;
};

export const createBulkDriverShifts = async (
  reqbody: ICreateBulkDriverShiftsReqBody
): Promise<AxiosResponse> => {
  return (await authenticatedAxiosInstance.axios.post(`/drivershift/bulk`, reqbody)).data;
};

export const deleteDriverShift = async (shiftId: number): Promise<string | null> => {
  return (await authenticatedAxiosInstance.axios.delete(`/drivershift/${shiftId}`)).data?.message;
};

// Get the available shifts for a trip based on capacity of trip, plus start and end times of trip
export const getAvailableShifts = async (
  demandCapacities: DemandCapacities,
  startDate: string,
  endDate: string
): Promise<IAvailableShift[]> => {
  const response = await authenticatedAxiosInstance.axios.get(
    `/shift/available-shifts?bikeCapacity=${demandCapacities.numBikes}&nonDisabledCapacity=${demandCapacities.numNonDisabled}&wheelchairCapacity=${demandCapacities.numWheelchairs}&startDate=${startDate}&endDate=${endDate}`
  );

  return response.data.shifts;
};

export const getDriverShiftsForAlgoReboot = async (
  startDate: string,
  endDate: string
): Promise<DriverShiftForReboot[]> => {
  const response = await authenticatedAxiosInstance.axios.get(
    `/drivershift/requires-reboot?startDate=${startDate}&endDate=${endDate}`
  );

  return response?.data?.data;
};

export const sendDriverShiftsForReboot = async (reqBody: DriverShiftsForRebootReqbody) => {
  return await authenticatedAxiosInstance.axios.post(`/drivershift/algo-reboot`, reqBody);
};
